import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login({ setAuth }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://globalnastrategiahnadlowanaxxiwiek.com/loginctx7', {
                username,
                password
            });
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('sessionId', response.data.sessionId);
            setAuth({
                token: response.data.token,
                sessionId: response.data.sessionId,
                isAuthenticated: true
            });
            navigate('/dashboard'); // Programmatically redirect to dashboard
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed!');
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                <button onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
}

export default Login;
