import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login.js';
import Dashboard from './components/Dashboard.js';

const App = () => {
  const [auth, setAuth] = useState({
    token: sessionStorage.getItem('token'),
    sessionId: sessionStorage.getItem('sessionId'),
    isAuthenticated: !!sessionStorage.getItem('token')
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('sessionId');
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!auth.isAuthenticated ? <Login setAuth={setAuth} /> : <Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={auth.isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
